import type { ApolloError } from '@apollo/client';

// TODO: remade temp resolver when backend changed it
const unauthorizedError = (error?: ApolloError) => {
    const errorMessage = error?.message.toLowerCase() ?? '';

    const match =
        errorMessage.search('token is wrong') >= 0 || errorMessage === 'token is wrong bearer';

    return match;
};

export { unauthorizedError };
