import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';
import { black, gray } from '@avangard/ui/colors';

type MoreItemsProps = {
    items: (string | null)[];
    className?: string;
};

const Root = styled.div<{ overflowLabel?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    min-height: 18px;

    > p {
        max-width: ${p => (p.overflowLabel ? 'calc(100% - 24px)' : '100%')};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

const Counter = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-inline-start: 4px;
    color: ${black[50]};
    font-size: 1.2rem;
    border-radius: 50%;
    background-color: ${gray[20]};
`;

const MoreItems = (props: MoreItemsProps): React.ReactElement => {
    const { items, ...otherProps } = props;

    const [firstItem, ...restItems] = items;

    const restItemsCount = restItems.length;
    const tooltipContent = items.join('<br />');

    const hasRestItems = restItemsCount > 0;

    return (
        <Root overflowLabel={hasRestItems} {...otherProps}>
            <p>{firstItem}</p>

            {hasRestItems ? (
                <Tooltip
                    arrow
                    placement='top'
                    title={<span dangerouslySetInnerHTML={{ __html: tooltipContent }} />}
                >
                    <Counter>+{restItemsCount}</Counter>
                </Tooltip>
            ) : null}
        </Root>
    );
};

export { MoreItems };
