export enum StudentsAddMode {
    'unlimited' = 'unlimited',
    'fullLimited' = 'fullLimited',
    'limited' = 'limited',
}

export const studentsAddModeTranslates = {
    [StudentsAddMode.unlimited]: 'common:blocks.students_add_mode.unlimited',
    [StudentsAddMode.fullLimited]: 'common:blocks.students_add_mode.fullLimited',
    [StudentsAddMode.limited]: 'common:blocks.students_add_mode.limited',
};

export const studentsAddModeOptions: AutocompleteOption[] = Object.entries(StudentsAddMode).map(
    ([, value]) => ({
        key: value,
        value: studentsAddModeTranslates[value],
    }),
);
