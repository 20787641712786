export enum Interest {
    music = 'music',
    films = 'films',
    games = 'games',
    books = 'books',
}

export const interestTranslates = {
    [Interest.music]: 'students:blocks.interest.music',
    [Interest.films]: 'students:blocks.interest.films',
    [Interest.games]: 'students:blocks.interest.games',
    [Interest.books]: 'students:blocks.interest.books',
};

export const interestOptions: AutocompleteOption[] = Object.entries(Interest).map(
    ([key, value]) => ({
        key,
        value: interestTranslates[value],
    }),
);
