import { BaseEntity, Entity as DecribeEntity, RelationList } from '@lib/entity';

import { StudentProfileItemEntity, StudentProfileItemEntityList } from '@modules/students/entities';

import type { ProfileSection } from '@modules/types/graphql';

export type Entity = Partial<ProfileSection> | null;

@DecribeEntity('StudentProfileSectionEntity')
class StudentProfileSectionEntity extends BaseEntity {
    name: string;

    @RelationList(() => StudentProfileItemEntityList)
    items: StudentProfileItemEntity[];

    constructor(studentProfileSection: Entity) {
        super(studentProfileSection);

        this.name = studentProfileSection?.name ?? '';
    }
}

export { StudentProfileSectionEntity };
