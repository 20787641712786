import React from 'react';
import styled from 'styled-components';

import { AvatarField } from './avatar-field';
import { AvatarEditorModal } from './avatar-editor-modal';

import type { FileInfoEntity } from '@modules/shared/entities';
import type { UploadedFile } from '@modules/shared/organisms';

type AvatarEditGroupProps = {
    label: string;
    title: string;
    photo: FileInfoEntity | null;
    openEditor: boolean;
    onUploadFile: (file?: UploadedFile) => void;
    onDeleteFile: () => void;
    onOpenEditor: () => void;
    onCloseEditor: () => void;
    onSaveFile?: (croppedThumbnailUrl: string) => void;
};

const Root = styled.div`
    width: 100%;
`;

const AvatarEditGroup = (props: AvatarEditGroupProps): React.ReactElement => {
    const {
        label,
        title,
        photo,
        openEditor,
        onOpenEditor,
        onCloseEditor,
        onUploadFile,
        onDeleteFile,
        onSaveFile,
    } = props;

    const file = photo?.exists() ? { fileId: photo.id, url: photo.croppedThumbnail } : undefined;

    return (
        <React.Suspense fallback=''>
            <Root>
                <AvatarField
                    fullWidth
                    label={label}
                    fullName={title}
                    file={file}
                    croppedThumbnail={photo?.croppedThumbnail}
                    onOpenEditor={onOpenEditor}
                    onUploadFile={onUploadFile}
                    onDeleteFile={onDeleteFile}
                />

                {photo?.exists() ? (
                    <AvatarEditorModal
                        photoId={photo.id}
                        cropInfo={photo.cropInfo}
                        onAccept={onSaveFile}
                        open={openEditor}
                        onClose={onCloseEditor}
                    />
                ) : null}
            </Root>
        </React.Suspense>
    );
};

export { AvatarEditGroup };
