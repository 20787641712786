import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@avangard/ui/core';

import { HeaderButtonBack } from '@modules/layout/atoms';

import type { DialogProps } from '@avangard/ui/core';

export type DialogSureBackProps = Pick<DialogProps, 'disabled' | 'onAccept' | 'onCancel'>;

const DialogSureBack = (props: DialogSureBackProps): React.ReactElement => {
    const { t } = useTranslation('common');

    return (
        <Dialog
            title={t('dialog.sure_back.title')}
            content={t('dialog.sure_back.content')}
            cancelText={t('actions.exit') ?? ''}
            acceptText={t('actions.save') ?? ''}
            {...props}
        >
            {dialogProps => {
                const { handleOpen } = dialogProps;

                return <HeaderButtonBack onClick={handleOpen} />;
            }}
        </Dialog>
    );
};

export { DialogSureBack };
