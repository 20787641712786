import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { ImageCropInfo } from '@modules/types/graphql';

export type Entity = Partial<ImageCropInfo>;

@DecribeEntity('ImageCropInfoEntity')
class ImageCropInfoEntity extends BaseEntity {
    x: number;
    y: number;
    width: number;
    height: number;
    scale: number;
    rotateAngle: number;

    constructor(imageCropInfo: Entity) {
        super(imageCropInfo);

        this.x = imageCropInfo?.x ?? 0;
        this.y = imageCropInfo?.y ?? 0;
        this.width = imageCropInfo?.width ?? 1; // 1 = 100% is original width
        this.height = imageCropInfo?.height ?? 1; // 1 = 100% is original height
        this.scale = imageCropInfo?.scale ?? 1;
        this.rotateAngle = imageCropInfo?.rotateAngle ?? 0;
    }

    getAvatarEditorValues() {
        const values = {
            position: {
                x: this.x + this.width / 2,
                y: this.y + this.height / 2,
            },
            scale: this.scale,
            rotate: this.rotateAngle * -1,
        };

        return values;
    }
}

export { ImageCropInfoEntity };
