import React from 'react';

import { ReactComponent as MobileHeaderLogoIcon } from './source/mobile-header-logo.svg';
import { Root } from './shared/styled';

import type { IconProps } from './shared/types';

const MobileHeaderLogo = (props: IconProps): React.ReactElement => {
    return (
        <Root {...props}>
            <MobileHeaderLogoIcon />
        </Root>
    );
};

export { MobileHeaderLogo };
