import { EntityList } from '@lib/entity';
import { SchoolEntity } from './school-entity';

import type { Entity } from './school-entity';

class SchoolEntityList extends EntityList<SchoolEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(SchoolEntity, items, count);
    }
}

export { SchoolEntityList };
