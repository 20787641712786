import React from 'react';
import { VictoryTooltip } from 'victory';

import { chartTheme, chartProps } from './chart-theme';

import type { VictoryTooltipProps } from 'victory';

const definitions = {
    horizontal: {
        topOffset: -1,
        leftOffset: 2,
    },
    vertical: {
        topOffset: -6,
        leftOffset: 0,
    },
};

const ChartBaseTooltip = (props: VictoryTooltipProps): React.ReactElement | null => {
    const { style, ...otherProps } = props;

    const isHorizontalChart = otherProps?.horizontal;

    const dy = isHorizontalChart
        ? definitions.horizontal.topOffset
        : definitions.vertical.topOffset;

    const dx = isHorizontalChart
        ? definitions.horizontal.leftOffset
        : definitions.vertical.leftOffset;

    const combinedTooltipStyles = {
        ...chartTheme.tooltip,
        ...style,
    };

    const combinedTooltipProps = {
        ...chartProps.tooltip,
        ...otherProps,
        dy,
        dx,
    };

    return (
        <VictoryTooltip
            constrainToVisibleArea
            style={combinedTooltipStyles}
            {...combinedTooltipProps}
        />
    );
};

ChartBaseTooltip.defaultEvents = (VictoryTooltip as any).defaultEvents;

export { ChartBaseTooltip };
