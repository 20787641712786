import React from 'react';
import styled from 'styled-components';
import { ClickAwayListener } from '@material-ui/core';
import { Menu } from '@material-ui/icons';

import { TriggerButton } from '@modules/layout/atoms';
import { PopperPopup } from '@modules/layout/moleculas';

type PopperActionsInjectChildrenProps = {
    onClose: () => void;
};

type PopperActionsProps = {
    children: (props: PopperActionsInjectChildrenProps) => React.ReactNode;
    icon?: any;
};

const ClickAwayInner = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > * {
        &:not(:last-child) {
            margin-inline-end: 8px;
        }
    }
`;

const StyledPopperPopup = styled(PopperPopup)`
    margin-top: 8px;
`;

const menuIcon = <Menu style={{ fontSize: 16 }} />;

const PopperActions = (props: PopperActionsProps): React.ReactElement => {
    const { icon = menuIcon, children } = props;

    const [anchorElement, setAnchorElement] = React.useState<HTMLElement | null>(null);

    const handlePopperToggle = (event: React.SyntheticEvent<HTMLElement>): void =>
        setAnchorElement(anchorElement ? null : event.currentTarget);

    const handlePopperClose = (): void => setAnchorElement(null);

    React.useEffect(() => () => setAnchorElement(null), []);

    return (
        <ClickAwayListener onClickAway={handlePopperClose}>
            <ClickAwayInner>
                <TriggerButton onClick={handlePopperToggle}>{icon}</TriggerButton>

                <StyledPopperPopup
                    placement='bottom-end'
                    open={!!anchorElement}
                    anchorEl={anchorElement}
                >
                    {children({ onClose: handlePopperClose })}
                </StyledPopperPopup>
            </ClickAwayInner>
        </ClickAwayListener>
    );
};

export { PopperActions };
