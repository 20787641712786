import { EntityList } from '@lib/entity';
import { LapEntity } from './lap-entity';

import type { Entity } from './lap-entity';

class LapEntityList extends EntityList<LapEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(LapEntity, items, count);
    }
}

export { LapEntityList };
