import type { FormikValues } from 'formik';

const getDynamicFieldError = (field: string, errors: FormikValues) => {
    const nested = field.indexOf('.') >= 0;

    if (!nested) {
        return errors[field];
    }

    const parts = field.split('.');

    const error = errors[parts[0]]?.[parts[1]];

    return error;
};

export { getDynamicFieldError };
