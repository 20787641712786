import type { FormikValues } from 'formik';

const getDynamicFieldValue = (field: string, values: FormikValues) => {
    const nested = field.indexOf('.') >= 0;

    if (!nested) {
        return values[field];
    }

    const parts = field.split('.');

    const value = values[parts[0]][parts[1]];

    return value;
};

export { getDynamicFieldValue };
