import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { StudentProfileSectionItem } from '@modules/types/graphql';

export type Entity = Partial<StudentProfileSectionItem> | null;

@DecribeEntity('StudentProfileItemEntity')
class StudentProfileItemEntity extends BaseEntity {
    label: string;
    link: string;
    value: string;

    constructor(profileItem: Entity) {
        super(profileItem);

        this.label = profileItem?.label ?? '';
        this.link = profileItem?.link ?? '';
        this.value = profileItem?.value ?? '';
    }
}

export { StudentProfileItemEntity };
