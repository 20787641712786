import { EntityList } from '@lib/entity';
import { BuildingEntity } from './building-entity';

import type { Entity } from './building-entity';

class BuildingEntityList extends EntityList<BuildingEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(BuildingEntity, items, count);
    }
}

export { BuildingEntityList };
