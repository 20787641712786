import { gql } from '@apollo/client';

import { FileInfoFragment } from './file-info';

export const FileDocFragment = gql`
    fragment FileDoc on FileDoc {
        name
        fileId
        file {
            ...FileInfo
        }
    }
    ${FileInfoFragment}
`;
