import { createCustomError } from '@lib/errors';
import { i18n } from '@lib/i18n';

import type { ImageCropInfo } from '@modules/types/graphql';

export type UpdateFileCropValues = {
    x: number;
    y: number;
    width: number;
    height: number;
    scale: number;
    rotate: number;
};

export type UpdateFileCropExtra = {
    photoId?: string | null;
};

class UpdateFileCropRequest {
    id: string;
    cropInfo: ImageCropInfo;

    constructor(values: UpdateFileCropValues, extra: UpdateFileCropExtra) {
        if (!extra.photoId) {
            throw createCustomError(i18n.t('errors:file.invalid_id'));
        }

        this.id = extra.photoId;

        this.cropInfo = {
            x: values.x ? values.x : 0,
            y: values.y ? values.y : 0,
            scale: values.scale ? values.scale : 0,
            width: values.width ? values.width : 0,
            height: values.height ? values.height : 0,
            rotateAngle: values.rotate * -1 ?? 0,
        };
    }
}

export { UpdateFileCropRequest };
