import React from 'react';
import styled from 'styled-components';
import { nanoid } from 'nanoid';
import { VictoryChart, VictoryArea, VictoryAxis, VictoryScatter } from 'victory';

import { chartTheme, chartProps } from './chart-theme';
import { ChartBaseTooltip } from './chart-base-tooltip';

import type {
    VictoryChartProps,
    VictoryAreaProps,
    VictoryScatterProps,
    VictoryAxisProps,
} from 'victory';

export type AreaChartProps = {
    data: any[];
    areaColor: string;
    x: string;
    y: string;
    labels?: VictoryAreaProps['labels'];
    ChartProps?: VictoryChartProps;
    AreaProps?: VictoryAreaProps;
    AxisProps?: {
        independent?: VictoryAxisProps;
        dependent?: VictoryAxisProps;
    };
    ScatterProps?: VictoryScatterProps;
    disableIndependentAxis?: boolean;
    disableDependentAxis?: boolean;
};

const Chart = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    min-height: 245px;
    overflow: hidden;
`;

const SvgPlaceholder = styled.svg`
    position: absolute;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
`;

const AreaChart = React.memo((props: AreaChartProps): React.ReactElement => {
    const {
        data,
        areaColor,
        x,
        y,
        labels,
        ChartProps,
        AreaProps = {},
        ScatterProps = {},
        AxisProps = {},
        disableIndependentAxis,
        disableDependentAxis,
        ...otherProps
    } = props;

    const chartRef = React.useRef<HTMLDivElement>(null);
    const gradientRefId = React.useRef<string>(`area-chart-gradient-${nanoid()}`);

    const gradientId = gradientRefId.current;

    const [chartWidth, setChartWidth] = React.useState<number>(0);

    const combinedChartProps = {
        ...chartProps.chart,
        ...ChartProps,
    };

    const { labelComponent, style: areaStyle, ...otherAreaProps } = AreaProps;
    const combinedAreaProps = {
        labelComponent: labelComponent || <ChartBaseTooltip />,
        ...chartProps.area,
        ...otherAreaProps,
    };

    const { style: scatterStyle, ...otherScatterProps } = ScatterProps;
    const combinedScatterProps = {
        labelComponent: combinedAreaProps.labelComponent,
        ...otherScatterProps,
    };

    React.useEffect(() => {
        const chartNode = chartRef?.current;

        if (chartNode && chartNode.clientWidth > 0 && chartWidth !== chartNode.clientWidth) {
            setChartWidth(chartNode.clientWidth);
        }
    }, [chartRef.current]);

    return (
        <Chart ref={chartRef} {...otherProps}>
            <SvgPlaceholder>
                <defs>
                    <linearGradient id={gradientId} x1='0%' y1='0%' x2='0%' y2='100%'>
                        <stop offset='0%' stopColor={areaColor} />
                        <stop offset='0%' stopColor={areaColor} stopOpacity='.2' />
                        <stop offset='100%' stopColor={areaColor} stopOpacity='0' />
                    </linearGradient>
                </defs>
            </SvgPlaceholder>

            {chartWidth ? (
                <VictoryChart width={chartWidth} {...combinedChartProps}>
                    <VictoryArea
                        x={x}
                        y={y}
                        data={data}
                        labels={labels}
                        style={{
                            data: {
                                fill: `url(#${gradientId})`,
                                stroke: areaColor,
                                strokeWidth: 2,
                            },
                            ...areaStyle,
                        }}
                        {...combinedAreaProps}
                    />

                    <VictoryScatter
                        x={x}
                        y={y}
                        data={data}
                        size={({ active }) => (active ? 4 : 0)}
                        style={{
                            data: {
                                fill: '#fff',
                                strokeWidth: 2,
                                stroke: areaColor,
                            },
                            ...scatterStyle,
                        }}
                        {...combinedScatterProps}
                    />

                    {disableIndependentAxis ? null : (
                        <VictoryAxis
                            fixLabelOverlap
                            style={chartTheme.independentAxis}
                            {...AxisProps.independent}
                        />
                    )}

                    {disableDependentAxis ? null : (
                        <VictoryAxis
                            dependentAxis
                            fixLabelOverlap
                            style={chartTheme.dependentAxis}
                            {...AxisProps.dependent}
                        />
                    )}
                </VictoryChart>
            ) : null}
        </Chart>
    );
});

export { AreaChart };
