/**
 * Create multiple errors object from flat errors notation
 * (some.errors[0], some.errors[1] -> { some.errors: {0: 'error', 1: 'error})
 * for formik modified bag
 *
 * @param {Record} errors
 * @param {string[]} slugs
 *
 * @return Record | null
 */
const createMultipleErrors = (
    errors: Record<string, any>,
    slugs: string[],
): Record<string, any> | null => {
    const multipleErrors: Record<string, any> = {};

    if (!slugs?.length) {
        return null;
    }

    slugs.forEach(slug => {
        multipleErrors[slug] = Object.entries(errors).reduce((acc, [key, item]) => {
            const existError = key.search(slug) > -1;

            if (!existError) {
                return acc;
            }

            const regExp = new RegExp(`(${slug}|\\[|\\])`, 'g');
            const errorIdx = key.replace(regExp, '');

            return { ...acc, [errorIdx]: item };
        }, {});
    });

    return multipleErrors;
};

export { createMultipleErrors };
