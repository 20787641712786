import qs from 'query-string';

type CreateLocationSearchFromStatePayload = {
    state: unknown;
    pathname?: string;
};

const createLocationSearchFromState = (props: CreateLocationSearchFromStatePayload) => {
    const { state, pathname = '../' } = props;

    if (typeof state !== 'object') {
        return pathname;
    }

    const sanitizeState: Record<string, any> = { ...state };

    delete sanitizeState.key;

    const query = qs.stringify(sanitizeState, { skipEmptyString: true });
    const search = `${pathname}?${query}`;

    return search;
};

export { createLocationSearchFromState };
