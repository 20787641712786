import React from 'react';
import styled from 'styled-components';
import { VictoryPie, VictoryLabel, VictoryTooltip } from 'victory';

import { chartProps } from './chart-theme';

import type { VictoryPieProps, VictoryLabelProps } from 'victory';

type PieChartProps = {
    data: any[];
    PieProps?: VictoryPieProps;
    LabelProps?: VictoryLabelProps;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
`;

const TOOLTIP_FLYOUT_STYLE = {
    stroke: 'transparent',
};

const PieChart = React.memo((props: PieChartProps): React.ReactElement => {
    const { data, PieProps = {}, LabelProps = {}, ...otherProps } = props;

    const chartRef = React.useRef<HTMLDivElement>(null);
    const [chartWidth, setChartWidth] = React.useState(0);

    const { height, ...otherPieProps } = PieProps;

    const combinedPieProps = {
        height,
        ...chartProps.pie,
        ...otherPieProps,
    };

    React.useEffect(() => {
        const chartNode = chartRef?.current;

        if (chartNode && chartNode.clientWidth > 0 && chartWidth !== chartNode.clientWidth) {
            setChartWidth(chartNode.clientWidth);
        }
    }, [chartRef.current]);

    return (
        <Root ref={chartRef} {...otherProps}>
            {chartWidth ? (
                <svg width={chartWidth} viewBox={`0 0 ${chartWidth} ${height}`}>
                    {LabelProps.text && height ? (
                        <VictoryLabel
                            textAnchor='middle'
                            x={chartWidth / 2}
                            y={height - 95}
                            {...LabelProps}
                        />
                    ) : null}

                    <VictoryPie
                        standalone={false}
                        width={chartWidth}
                        data={data}
                        labelPosition='centroid'
                        labelComponent={
                            <VictoryTooltip
                                orientation='right'
                                flyoutStyle={TOOLTIP_FLYOUT_STYLE}
                            />
                        }
                        {...combinedPieProps}
                    />
                </svg>
            ) : null}
        </Root>
    );
});

export { PieChart };
