const getShortFilename = (name: string): string => {
    let [filename, extension] = name.split('.');

    if (filename.length > 20) {
        filename = filename.substring(0, 20);
    }

    const result = filename + '...' + extension;

    return result;
};

export { getShortFilename };
