import { UserRole } from '@modules/types/graphql';

export const roleTranslates = {
    [UserRole.superAdmin]: 'common:blocks.user_roles.super_admin',
    [UserRole.admin]: 'common:blocks.user_roles.admin',
    [UserRole.instructor]: 'common:blocks.user_roles.instructor',
    [UserRole.seniorInstructor]: 'common:blocks.user_roles.senior_instructor',
    [UserRole.mentor]: 'common:blocks.user_roles.mentor',
    [UserRole.juniorLeader]: 'common:blocks.user_roles.junior_leader',
    [UserRole.leader]: 'common:blocks.user_roles.leader',
    [UserRole.school]: 'common:blocks.user_roles.school',
    [UserRole.student]: 'common:blocks.user_roles.student',
    [UserRole.methodist]: 'common:blocks.user_roles.methodist',
    [UserRole.registrar]: 'common:blocks.user_roles.registrar',
    [UserRole.psychologist]: 'common:blocks.user_roles.psychologist',
    [UserRole.organizingTeacher]: 'common:blocks.user_roles.organizing_teacher',
};
