import { gql } from '@apollo/client';

export type UploadFileRestMutationType = {
    response?: {
        file_id: number;
    };
};

export const UploadFileRestMutation = gql`
    mutation UploadFileRest($input: File!) {
        uploadFile(input: $input)
            @rest(type: "File", path: "/files", method: "POST", bodySerializer: "fileEncode") {
            file_id
        }
    }
`;
