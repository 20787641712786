import React from 'react';
import styled from 'styled-components';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useTranslation } from 'react-i18next';
import { VariableSizeList } from 'react-window';
import { makeStyles, TableContainer, Table, TableHead, TableBody } from '@material-ui/core';
import { black, white } from '@avangard/ui/colors';
import { breakpoints } from '@avangard/ui/utils';

import { CommonHelper } from '@helpers';
import { EmptyList } from '@modules/layout/icons';
import { VirtualizedTableBaseRow } from './virtualized-table-base-row';
import { VirtualizedTableCell } from './virtualized-table-cell';

import type { ListChildComponentProps } from 'react-window';

export type VirtualizedTableCellType = {
    id: string;
    label: string;
    align?: 'left' | 'center' | 'right';
    width?: number;
};

export type VirtualizedTableTotalProps = {
    title?: string | string[];
    value?: number;
    disabled?: boolean;
};

type VirtualizedTableProps = {
    id: string;
    data: {
        items: any | null;
    } & Record<string, any>;
    columns: VirtualizedTableCellType[];
    total: VirtualizedTableTotalProps;
    TableHeadComponent: React.ReactElement;
    TableRowComponent: React.ComponentType<ListChildComponentProps>;
    itemSize?: number;
    loading?: boolean;
};

const useTableStyles = makeStyles({
    tableContainer: {
        minHeight: 48,
        height: '100%',
    },
    table: {
        width: '100%',
        height: '100%',
    },
    thead: {
        width: '100%',
        color: black[80],
        backgroundColor: white[90],
    },
    tbody: {
        width: '100%',
    },
});

const Root = styled.div`
    width: 100%;
    height: 100%;
`;

const Footer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0;
`;

const Empty = styled.div`
    width: 100%;
    margin: 32px 0;
    text-align: center;

    > p {
        margin-top: 4px;
        color: ${black[20]};
    }
`;

const FooterTotal = styled.div`
    > p {
        font-size: 1.4rem;
    }

    ${breakpoints.down('xs')} {
        order: 1;
    }
`;

const VirtualizedTable = (props: VirtualizedTableProps): React.ReactElement => {
    const {
        id,
        data,
        columns,
        total,
        loading,
        itemSize = 52,
        TableHeadComponent,
        TableRowComponent,
    } = props;

    const { t } = useTranslation('common');

    const listRef = React.useRef<any>(null);

    const tableClasses = useTableStyles();
    const hasRows = total.value !== 0;

    const getItemSize = (_: number) => itemSize;

    const renderedTotal = React.useMemo((): React.ReactElement | null => {
        if (total.disabled || !total.title) {
            return null;
        }

        const totalValue = total.value ?? 0;

        let displayTotal = '';

        if (Array.isArray(total.title)) {
            const titles = total.title.map(title => title.replace('%d', String(totalValue)));

            displayTotal = CommonHelper.createCountFormatter(totalValue, titles, false);
        } else {
            displayTotal = total.title.replace('%d', String(totalValue));
        }

        return (
            <FooterTotal>
                <p>{displayTotal}</p>
            </FooterTotal>
        );
    }, [total.value]);

    const itemCount = Array.isArray(data.items) ? data.items.length : +!!data.items;

    const itemData = {
        ...data,
        columns,
    };

    return (
        <Root>
            <TableContainer className={tableClasses.tableContainer}>
                <Table component='div' id={id} className={tableClasses.table}>
                    <TableHead component='div' className={tableClasses.thead}>
                        {TableHeadComponent}
                    </TableHead>

                    <TableBody component='div' className={tableClasses.tbody}>
                        {!loading && !hasRows ? (
                            <VirtualizedTableBaseRow>
                                <VirtualizedTableCell width='100%' colSpan={12}>
                                    <Empty>
                                        <EmptyList />
                                        <p>{t('stubs.empty')}</p>
                                    </Empty>
                                </VirtualizedTableCell>
                            </VirtualizedTableBaseRow>
                        ) : (
                            <AutoSizer>
                                {autoSizerProps => {
                                    const { width, height } = autoSizerProps;

                                    return (
                                        <VariableSizeList
                                            ref={listRef}
                                            width={width}
                                            height={height}
                                            itemKey={index => index}
                                            itemCount={itemCount}
                                            itemSize={getItemSize}
                                            itemData={itemData}
                                        >
                                            {TableRowComponent}
                                        </VariableSizeList>
                                    );
                                }}
                            </AutoSizer>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Footer>{renderedTotal}</Footer>
        </Root>
    );
};

export { VirtualizedTable };
