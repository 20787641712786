import { EntityList } from '@lib/entity';
import { StudentEntity } from './student-entity';

import type { Entity } from './student-entity';

class StudentEntityList extends EntityList<StudentEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(StudentEntity, items, count);
    }
}

export { StudentEntityList };
