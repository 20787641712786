import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';

import { FileInfoEntity } from '@modules/shared/entities';

import type { StudentMiniInfo } from '@modules/types/graphql';

export type Entity = StudentMiniInfo | null;

@DecribeEntity('StudentMiniInfoEntity')
class StudentMiniInfoEntity extends BaseEntity {
    id: string;
    fullName: string;
    company: number;
    platoon: number;
    platoonX: number;
    isOnline: boolean;
    isCommercial: boolean;
    isOut: boolean;
    isCertificate: boolean;

    @Relation(() => FileInfoEntity)
    photo: FileInfoEntity;

    constructor(studentMiniInfo: Entity) {
        super(studentMiniInfo);

        this.id = studentMiniInfo?.id ?? '';
        this.fullName = studentMiniInfo?.fullName ?? '';
        this.company = studentMiniInfo?.company ?? 0;
        this.platoon = studentMiniInfo?.platoon ?? 0;
        this.platoonX = studentMiniInfo?.platoonX ?? 0;
        this.isOnline = studentMiniInfo?.isOnline ?? false;
        this.isCommercial = studentMiniInfo?.isCommercial ?? false;
        this.isOut = studentMiniInfo?.isOut ?? false;
        this.isCertificate = studentMiniInfo?.isCertificate ?? false;
    }
}

export { StudentMiniInfoEntity };
