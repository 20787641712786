import { Token } from './token';

export type SetTokenSessionPayload = {
    accessToken?: string | null;
    refreshToken?: string | null;
};

class TokenSession {
    static getAccessTokenField() {
        return 'accessToken';
    }

    static getRefreshTokenField() {
        return 'refreshToken';
    }

    static getCurrentSession() {
        return new TokenSession();
    }

    static setCurrentSession(payload: SetTokenSessionPayload) {
        try {
            if (payload.accessToken) {
                Token.saveInStorage(TokenSession.getAccessTokenField(), payload.accessToken);
            }

            if (payload.refreshToken) {
                Token.saveInStorage(TokenSession.getRefreshTokenField(), payload.refreshToken);
            }
        } catch (e) {
            console.log(e);
        }
    }

    static async destroyCurrentSession() {
        try {
            Token.destroyInStorage(TokenSession.getAccessTokenField());
            Token.destroyInStorage(TokenSession.getRefreshTokenField());
        } catch (e) {
            console.log(e);
        }
    }

    hasTokens() {
        const isset = this.getAccessToken().issetToken() || this.getRefreshToken().issetToken();

        return isset;
    }

    getAccessToken(): Token {
        const accessToken = new Token(TokenSession.getAccessTokenField());

        return accessToken;
    }

    getRefreshToken(): Token {
        const refreshToken = new Token(TokenSession.getRefreshTokenField());

        return refreshToken;
    }
}

export { TokenSession };
