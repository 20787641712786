import { PerformanceManagement } from '@modules/types/graphql';

export const performanceManagmentTranslates = {
    [PerformanceManagement.forPassingTests]:
        'students:blocks.performance_management.for_passing_tests',
    [PerformanceManagement.forActivity]: 'students:blocks.performance_management.for_activity',
    [PerformanceManagement.forAcademicPerformance]:
        'students:blocks.performance_management.for_academic_performance',
};

export const performanceManagementOptions: AutocompleteOption[] = Object.entries(
    PerformanceManagement,
).map(([key, value]) => ({
    key,
    value: performanceManagmentTranslates[value],
}));
