import { CreditOfPoint } from '@modules/types/graphql';

export const creditOfPointTranslates = {
    [CreditOfPoint.inCompany]: 'students:blocks.credit_of_points.in_company',
    [CreditOfPoint.inPlatoon]: 'students:blocks.credit_of_points.in_platoon',
    [CreditOfPoint.personalCredit]: 'students:blocks.credit_of_points.personal_credit',
    [CreditOfPoint.ranks]: 'students:blocks.credit_of_points.ranks',
    [CreditOfPoint.ratings]: 'students:blocks.credit_of_points.ratings',
};

export const creditOfPointOptions: AutocompleteOption[] = Object.entries(CreditOfPoint).map(
    ([key, value]) => ({
        key,
        value: creditOfPointTranslates[value],
    }),
);
