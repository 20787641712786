import React from 'react';
import styled from 'styled-components';
import { makeStyles, ClickAwayListener } from '@material-ui/core';
import { Autocomplete } from '@avangard/ui/core';
import { concatenateClasses } from '@avangard/ui/utils';

import type { AutocompleteProps } from '@avangard/ui/core';

type FormIndependentAutocompleteProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
> = AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & {
    className?: string;
};

// we need use styles this way, because material-ui
// has bug with attaching popover while scrolling on mobile
// (popover scrolling with page)
//
// We need to disablePortal and hideBackdrop
// and force attach popover to anchor element
const useAutocompletePlatoonPopoverStyles = makeStyles({
    popper: {
        position: 'absolute !important' as any,
        zIndex: '98 !important' as any,
    },
    paper: {
        top: '24px !important',
        left: '0 !important',
    },
});

const Root = styled.div``;

const FormIndependentAutocomplete = <
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
>(
    props: FormIndependentAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
): React.ReactElement => {
    const { classes, className, ...otherProps } = props;

    const autocompletePlatoonPopoverClasses = concatenateClasses(
        useAutocompletePlatoonPopoverStyles(),
        classes,
    );

    const [open, setOpen] = React.useState<boolean>(false);

    const handleOpen = (): void => setOpen(true);
    const handleClose = (): void => setOpen(false);

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Root className={className}>
                <Autocomplete
                    {...otherProps}
                    disablePortal
                    classes={autocompletePlatoonPopoverClasses}
                    open={open}
                    onOpen={handleOpen}
                    onClose={handleClose}
                />
            </Root>
        </ClickAwayListener>
    );
};

export { FormIndependentAutocomplete };
