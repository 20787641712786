import { EntityList } from '@lib/entity';
import { FileInfoEntity } from './file-info-entity';

import type { Entity } from './file-info-entity';

class FileInfoEntityList extends EntityList<FileInfoEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(FileInfoEntity, items, count);
    }
}

export { FileInfoEntityList };
