import { createCdnLink } from './create-cdn-link';

type Payload = {
    path?: string | null;
    download?: boolean;
};

const createFileLink = (payload: Payload) => {
    const { path, download } = payload;

    let link = `files/${path}`;

    if (download === true) {
        link += '?download=true';
    }

    const result = createCdnLink(link);

    return result;
};

export { createFileLink };
