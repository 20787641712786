export const tablePerPage = [
    {
        titleKey: 'common:table.per_page.ten',
        value: 10,
    },
    {
        titleKey: 'common:table.per_page.twenty',
        value: 20,
    },
    {
        titleKey: 'common:table.per_page.fifty',
        value: 50,
    },
    {
        titleKey: 'common:table.per_page.one_hundred',
        value: 100,
    },
];
