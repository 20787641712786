import React from 'react';
import { usePrevious } from 'react-use';
import { useLocation } from '@reach/router';

import { useCurrentUser } from '@modules/user/hooks';

// function include in %REACT_APP_URL%/avt/script.js (connect in public/index.html)
const TrackWidget = (): null => {
    const location = useLocation();

    const { currentUser } = useCurrentUser({ fetchPolicy: 'cache-only' });
    const previousCurrentUser = usePrevious(currentUser);

    React.useEffect(() => {
        if (previousCurrentUser?.id === currentUser.id) {
            return;
        }

        if (typeof avtStop === 'undefined' || typeof avtStart === 'undefined') {
            return;
        }

        try {
            if (!currentUser.authenticated()) {
                avtStop();
            } else {
                avtStart(currentUser.id);
            }
        } catch (e) {
            console.log(e);
        }
    }, [currentUser, location.pathname]);

    return null;
};

export { TrackWidget };
