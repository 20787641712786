import React from 'react';
import { makeStyles, TableCell } from '@material-ui/core';
import { black } from '@avangard/ui/colors';
import { concatenateClasses } from '@avangard/ui/utils';

import type { Theme, TableCellProps } from '@material-ui/core';

type VirtualizedTableCellProps = TableCellProps;

const alignJustifyContentMap = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
};

const useTableCellStyles = makeStyles<Theme, Pick<VirtualizedTableCellProps, 'width' | 'align'>>({
    root: p => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: alignJustifyContentMap[p.align ?? 'left'],
        flex: '1 1 auto',
        maxWidth: '100%',
        minHeight: 46,
        width: p.width ?? 0,
        padding: '8px 16px',
        fontSize: '1.4rem',
        borderBottom: 0,

        '&$paddingCheckbox': {
            padding: 0,
        },
    }),
    head: {
        color: black[80],
    },
    paddingCheckbox: () => ({}),
});

const VirtualizedTableCell = (props: VirtualizedTableCellProps): React.ReactElement => {
    const { classes, width, align, children, ...otherProps } = props;

    const tableCellClasses = concatenateClasses(useTableCellStyles({ width, align }), classes);

    return (
        <TableCell component='div' classes={tableCellClasses} {...otherProps}>
            {children}
        </TableCell>
    );
};

export { VirtualizedTableCell };
