import { gql } from '@apollo/client';

export const FileInfoFragment = gql`
    fragment FileInfo on FileInfo {
        id
        name
        mime
        origin
        thumbnail
        croppedThumbnail
        cropInfo {
            x
            y
            width
            height
            rotateAngle
            scale
        }
        createdAt
    }
`;
