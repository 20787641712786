import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { breakpoints } from '@avangard/ui/utils';

import { RedLogo } from '@modules/layout/icons';

type EmptyLayoutProps = {
    children?: React.ReactElement;
};

const headerHeight = '70px';
const logoSize = '186px';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const Header = styled.div`
    height: ${headerHeight};
    width: 100%;
    flex: 0 0 auto;
    position: relative;
    background-image: url('/images/main-background.jpg');
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Logo = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-inline-start: calc(-${logoSize} + 56px);

    ${breakpoints.down('md')} {
        margin-inline-start: 0;
    }
`;

const LogoImage = styled.div`
    width: ${logoSize};
    height: ${logoSize};
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    & > * {
        height: 100%;
        width: 100%;
    }

    ${breakpoints.down(560)} {
        width: calc(${logoSize} / 1.5);
        height: calc(${logoSize} / 1.5);
    }
`;

const LogoLabel = styled.p`
    margin-inline-start: 26px;
    margin-top: 42px;
    font-size: 1.6rem;
    line-height: 1.5;

    ${breakpoints.down('xs')} {
        display: none;
    }
`;

const Content = styled.div`
    flex: 1 1 auto;
    margin-top: 42px;
    padding: calc(${logoSize} - ${headerHeight}) 15px;

    ${breakpoints.down(560)} {
        padding: calc(${logoSize} / 1.5 - ${headerHeight}) 30px;
    }
`;

const ErrorLayout = (props: EmptyLayoutProps): React.ReactElement => {
    const { children } = props;

    const { t } = useTranslation('errors');

    return (
        <Root>
            <Header>
                <Logo>
                    <LogoImage>
                        <RedLogo />
                    </LogoImage>

                    <LogoLabel>
                        {t('layout.title.part_1')} <br />
                        {t('layout.title.part_2')}
                    </LogoLabel>
                </Logo>
            </Header>

            <Content>{children}</Content>
        </Root>
    );
};

export { ErrorLayout };
