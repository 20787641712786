import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { StudentProfileCounts } from '@modules/types/graphql';

export type Entity = StudentProfileCounts | null;

@DecribeEntity('StudentProfileCountsEntity')
class StudentProfileCountsEntity extends BaseEntity {
    id: string;
    fullName: string;
    egScore: number;
    evaluationCriteriaScore: number;
    placeInOverallRatings: number;
    placeInPlatoonRatings: number;

    constructor(studentProfileCounts: Entity) {
        super(studentProfileCounts);

        this.id = studentProfileCounts?.id ?? '';
        this.fullName = studentProfileCounts?.fullName ?? '';
        this.egScore = studentProfileCounts?.egScore ?? 0;
        this.evaluationCriteriaScore = studentProfileCounts?.evaluationCriteriaScore ?? 0;
        this.placeInOverallRatings = studentProfileCounts?.placeInOverallRatings ?? 0;
        this.placeInPlatoonRatings = studentProfileCounts?.placeInPlatoonRatings ?? 0;
    }
}

export { StudentProfileCountsEntity };
