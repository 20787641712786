import React from 'react';
import { makeStyles, TableRow as MuiTableRow } from '@material-ui/core';
import { red } from '@avangard/ui/colors';
import { concatenateClasses } from '@avangard/ui/utils';

import type { Theme, TableRowProps as MuiTableRowProps } from '@material-ui/core';

export type TableRowProps = Omit<MuiTableRowProps, 'size' | 'children'> & {
    children: React.ReactNode;
    size?: 'xs' | 's' | 'm' | 'l' | 'xl'; // size - overide material ui API
    onClick?: (...args: any[]) => void;
};

const sizesMap = {
    xs: {
        height: 44,
    },
    s: {
        height: 52,
    },
    m: {
        height: 60,
    },
    l: {
        height: 68,
    },
    xl: {
        height: 76,
    },
};

const useTableRowStyles = makeStyles<Theme, { clickable?: boolean }>({
    root: p =>
        !!p.clickable
            ? {
                  cursor: 'pointer',

                  '&:hover > td': {
                      backgroundColor: red[10],
                  },
              }
            : {},
});

const useTableCellStyles = makeStyles<Theme, Required<Pick<TableRowProps, 'size'>>>({
    root: p => ({
        ...sizesMap[p.size],
    }),
});

const TableRow = (props: TableRowProps): React.ReactElement => {
    const { size = 's', classes, children, onClick, ...otherProps } = props;

    const clickable = typeof onClick !== 'undefined';

    const tableRowClasses = concatenateClasses(useTableRowStyles({ clickable }), classes);
    const tableCellClasses = useTableCellStyles({ size });

    return (
        <MuiTableRow classes={tableRowClasses} onClick={onClick} {...otherProps}>
            {React.Children.map(children, (child: any) => {
                if (!child) {
                    return null;
                }

                if (!('props' in child)) {
                    return child;
                }

                const { classes, ...childProps } = child.props;

                const cellClasses = concatenateClasses(tableCellClasses, classes);

                return React.cloneElement(child, {
                    classes: cellClasses,
                    ...childProps,
                });
            })}
        </MuiTableRow>
    );
};

export { TableRow };
