import React from 'react';
import { makeStyles, TableRow as MuiTableRow } from '@material-ui/core';
import { white, red } from '@avangard/ui/colors';

import type { Theme, TableRowProps } from '@material-ui/core';

type VirtualizedTableBaseRowProps = TableRowProps & {
    head?: boolean;
};

const useTableRowStyles = makeStyles<
    Theme,
    Pick<VirtualizedTableBaseRowProps, 'head'> & { clickable?: boolean }
>({
    root: p => {
        let properties: Record<string, any> = {
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            alignItems: 'center',
            minWidth: '100%',
            width: '100%',
            paddingRight: !!p.head ? '16px' : 0,
            borderBottom: `1px solid ${white[70]}`,
            transition: 'background-color .2s',
        };

        if (p.clickable) {
            properties = {
                ...properties,
                cursor: 'pointer',

                '&:hover': {
                    backgroundColor: red[10],
                },
            };
        }

        return properties;
    },
});

const VirtualizedTableBaseRow = React.forwardRef<HTMLDivElement, VirtualizedTableBaseRowProps>(
    (props, ref): React.ReactElement => {
        const { head, children, ...otherProps } = props;

        const clickable = !!otherProps.onClick;

        const tableRowClasses = useTableRowStyles({ head, clickable });

        return (
            <MuiTableRow component='div' ref={ref} classes={tableRowClasses} {...otherProps}>
                {children}
            </MuiTableRow>
        );
    },
);

export { VirtualizedTableBaseRow };
