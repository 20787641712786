import React from 'react';
import styled from 'styled-components';
import { ArrowBack } from '@material-ui/icons';
import { breakpoints } from '@avangard/ui/utils';

type HeaderButtonBackProps = {
    onClick: () => void;
};

const BackIcon = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-top: 2px;
    padding-inline-end: 16px;
    cursor: pointer;

    ${breakpoints.down('sm')} {
        padding-inline-end: 12px;
    }
`;

const arrowBackIcon = <ArrowBack style={{ color: 'inherit', fontSize: 24 }} />;

const HeaderButtonBack = (props: HeaderButtonBackProps) => {
    const { onClick } = props;

    return <BackIcon onClick={onClick}>{arrowBackIcon}</BackIcon>;
};

export { HeaderButtonBack };
