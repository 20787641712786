import React from 'react';
import { withStyles } from '@material-ui/core';
import { Button } from '@avangard/ui/core';

import type { ButtonProps } from '@avangard/ui/core';

const StyledButton = withStyles({
    root: {
        minWidth: 32,
        padding: 0,
    },
    label: {
        '& > span': {
            display: 'flex',
        },
    },
})(Button);

const TriggerButton = (props: ButtonProps): React.ReactElement => (
    <StyledButton variant='outlined' {...props} />
);

export { TriggerButton };
