import React from 'react';

import { ReactComponent as RedLogoIcon } from './source/red-logo.svg';
import { Root } from './shared/styled';

import type { IconProps } from './shared/types';

const RedLogo = (props: IconProps): React.ReactElement => {
    return (
        <Root {...props}>
            <RedLogoIcon />
        </Root>
    );
};

export { RedLogo };
