import React from 'react';
import styled from 'styled-components';
import { black } from '@avangard/ui/colors';
import { breakpoints } from '@avangard/ui/utils';

type WidgetsBlockProps = {
    children: React.ReactNode;
    title?: string | null;
    className?: string;
};

const Root = styled.div`
    width: 100%;
`;

const Title = styled.div`
    margin-bottom: 24px;
    color: ${black[80]};
    font-size: 1.4rem;
    font-weight: 600;
`;

const List = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 24px;
    width: 100%;

    ${breakpoints.down('xs')} {
        grid-gap: 12px;
        margin-bottom: 12px;
    }
`;

const WidgetsBlock = (props: WidgetsBlockProps): React.ReactElement => {
    const { className, title, children } = props;

    return (
        <Root className={className}>
            {title ? <Title>{title}</Title> : null}
            <List>{children}</List>
        </Root>
    );
};

export { WidgetsBlock };
