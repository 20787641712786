import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { BuildingRoom } from '@modules/types/graphql';

export type Entity = Partial<BuildingRoom> | null;

@DecribeEntity('BuildingRoomEntity')
class BuildingRoomEntity extends BaseEntity {
    id: string;
    name: string;

    constructor(buildingRoom: Entity) {
        super(buildingRoom);

        this.id = buildingRoom?.id ?? '';
        this.name = buildingRoom?.name ?? '';
    }
}

export { BuildingRoomEntity };
