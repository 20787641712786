import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { black, red } from '@avangard/ui/colors';

import { Avatar } from '@modules/shared/moleculas';
import { FileUploader } from '@modules/shared/organisms';

import type { UploadedFile } from '@modules/shared/organisms';

type AvatarFieldProps = {
    fullName: string;
    onUploadFile: (file?: UploadedFile) => void;
    onOpenEditor: () => void;
    label?: string;
    fullWidth: boolean;
    file?: UploadedFile;
    croppedThumbnail?: string | null;
    onDeleteFile?: () => void;
};

const Root = styled.div<{ fullWidth?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    ${p =>
        !!p.fullWidth &&
        css`
            width: 100%;
        `}
`;

const Label = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 8px;
    color: ${black[80]};
    font-size: 1.4rem;
    line-height: 22px;
    pointer-events: auto;
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    & > * {
        &:not(:last-child) {
            margin-inline-end: 10px;
        }
    }
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    max-width: calc(100% - 40px - 10px);
    flex: 1 1 auto;

    & > * {
        &:not(:last-child) {
            margin-inline-end: 6px;
        }
    }
`;

const EditButton = styled.div`
    padding: 2px;
    color: ${black[80]};
    font-size: 1.4em;
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
        color: ${red[100]};
    }
`;

const DeleteIcon = styled(IconButton)`
    padding: 4px;
`;

const deleteIcon = <Delete style={{ fontSize: 18 }} />;

const AvatarField = (props: AvatarFieldProps): React.ReactElement => {
    const {
        label,
        fullWidth,
        fullName,
        file,
        croppedThumbnail,
        onUploadFile,
        onDeleteFile,
        onOpenEditor,
    } = props;

    const { t } = useTranslation(['common', 'errors']);

    return (
        <Root fullWidth={fullWidth}>
            {label ? <Label>{label}</Label> : null}

            <Content>
                <Avatar fullName={fullName} croppedThumbnail={croppedThumbnail} />

                <Actions>
                    {!file ? (
                        <FileUploader
                            fullWidth
                            withoutIcon
                            validationType='image'
                            validationError={t('errors:avatar.invalid_image_format') ?? ''}
                            label={t('common:blocks.avatar.add') ?? ''}
                            file={file}
                            onUploadFile={onUploadFile}
                            onDeleteFile={onDeleteFile}
                        />
                    ) : (
                        <>
                            <EditButton onClick={onOpenEditor}>
                                {t('common:actions.edit_photo')}
                            </EditButton>
                            <DeleteIcon onClick={onDeleteFile}>{deleteIcon}</DeleteIcon>
                        </>
                    )}
                </Actions>
            </Content>
        </Root>
    );
};

export { AvatarField };
