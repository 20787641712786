import React from 'react';
import styled from 'styled-components';
import { makeStyles, Popper as MuiPopper, Grow, Paper } from '@material-ui/core';
import { concatenateClasses } from '@avangard/ui/utils';

import type { PopperProps, GrowProps, PaperProps } from '@material-ui/core';

type PopperPopupProps = PopperProps & {
    GrowProps?: GrowProps;
    PaperProps?: PaperProps;
};

const usePaperStyles = makeStyles({
    root: {
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    },
});

const StyledPopper = styled(MuiPopper)`
    z-index: 60;
`;

const PopperPopup = (props: PopperPopupProps): React.ReactElement => {
    const { GrowProps, PaperProps, children, ...otherProps } = props;

    const paperClasses = concatenateClasses(usePaperStyles(), PaperProps?.classes);

    return (
        <StyledPopper transition {...otherProps}>
            {popperProps => {
                const { TransitionProps } = popperProps;

                return (
                    <Grow
                        timeout={350}
                        style={{ transformOrigin: '0 0 0' }}
                        {...GrowProps}
                        {...TransitionProps}
                    >
                        <Paper {...PaperProps} classes={paperClasses}>
                            {children}
                        </Paper>
                    </Grow>
                );
            }}
        </StyledPopper>
    );
};

export { PopperPopup };
