import React from 'react';

import { VirtualizedTableBaseRow } from './virtualized-table-base-row';

import type { ListChildComponentProps } from 'react-window';
import type { VirtualizedTableCellType } from './virtualized-table';

export type VirtualizedTableRowProps<T = any> = Omit<ListChildComponentProps, 'data'> & {
    data: {
        items: T[];
        columns: VirtualizedTableCellType[];
    } & Record<string, any>;
    children: React.ReactNode;
    onClick?: () => void;
};

const VirtualizedTableRow = (props: VirtualizedTableRowProps): React.ReactElement => {
    const { style, children, onClick } = props;

    const rowRef = React.useRef<HTMLDivElement | null>(null);

    return (
        <VirtualizedTableBaseRow ref={rowRef} style={style} onClick={onClick}>
            {children}
        </VirtualizedTableBaseRow>
    );
};

export { VirtualizedTableRow };
