import { EntityList } from '@lib/entity';
import { LapAccessEntity } from './lap-access-entity';

import type { Entity } from './lap-access-entity';

class LapAccessEntityList extends EntityList<LapAccessEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(LapAccessEntity, items, count);
    }
}

export { LapAccessEntityList };
