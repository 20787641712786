import { BaseEntity, Entity as DecribeEntity, RelationList } from '@lib/entity';
import { BuildingRoomEntity, BuildingRoomEntityList } from '@modules/students/entities';

import type { Building } from '@modules/types/graphql';

export type Entity = Partial<Building> | null;

@DecribeEntity('BuildingEntity')
class BuildingEntity extends BaseEntity {
    id: string;
    name: string;

    @RelationList(() => BuildingRoomEntityList)
    rooms: BuildingRoomEntity[];

    constructor(building: Entity) {
        super(building);

        this.id = building?.id ?? '';
        this.name = building?.name ?? '';
    }
}

export { BuildingEntity };
