import React from 'react';
import { useSnackbar } from 'notistack';

import type { SnackbarMessage, OptionsObject } from 'notistack';

// TODO: temp any - when update all catch error types need remove
type CustomSnackbarMessage = SnackbarMessage | Record<string, any> | any;

const extractMessage = (customMessage: CustomSnackbarMessage) => {
    if (customMessage === null) {
        return '';
    }

    if (typeof customMessage === 'object') {
        return 'message' in customMessage ? customMessage.message : JSON.stringify(customMessage);
    }

    return customMessage;
};

const useEnqueueStacks = () => {
    const { enqueueSnackbar } = useSnackbar();

    const enqueueDefault = React.useCallback(
        (message: CustomSnackbarMessage, options?: OptionsObject) =>
            enqueueSnackbar(extractMessage(message), { variant: 'default', ...options }),
        [],
    );

    const enqueueError = React.useCallback(
        (message: CustomSnackbarMessage, options?: OptionsObject) =>
            enqueueSnackbar(extractMessage(message), { variant: 'error', ...options }),
        [],
    );

    const enqueueSuccess = React.useCallback(
        (message: CustomSnackbarMessage, options?: OptionsObject) =>
            enqueueSnackbar(extractMessage(message), { variant: 'success', ...options }),
        [],
    );

    const enqueueWarning = React.useCallback(
        (message: CustomSnackbarMessage, options?: OptionsObject) =>
            enqueueSnackbar(extractMessage(message), { variant: 'warning', ...options }),
        [],
    );

    const enqueueInfo = React.useCallback(
        (message: CustomSnackbarMessage, options?: OptionsObject) =>
            enqueueSnackbar(extractMessage(message), { variant: 'info', ...options }),
        [],
    );

    return React.useMemo(
        () => ({
            enqueueDefault,
            enqueueError,
            enqueueSuccess,
            enqueueWarning,
            enqueueInfo,
        }),
        [],
    );
};

export { useEnqueueStacks };
