import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';

const PopperActionsStyled = {
    StyledMenuItem: styled(MenuItem)`
        > span {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-inline-end: 16px;
        }
    `,
};

export { PopperActionsStyled };
