import React from 'react';
import styled from 'styled-components';
import { makeStyles, ClickAwayListener } from '@material-ui/core';
import { TextField } from '@avangard/ui/core';

import type { TextFieldProps } from '@avangard/ui/core';

type FormIndependentSelectProps = TextFieldProps & {
    className?: string;
};

// we need use styles this way, because material-ui
// has bug with attaching popover while scrolling on mobile
// (popover scrolling with page)
//
// We need to disablePortal and hideBackdrop
// and force attach popover to anchor element
const useSelectPlatoonPopoverStyles = makeStyles({
    root: {
        position: 'absolute !important' as any,
        zIndex: '98 !important' as any,
    },
    paper: {
        top: '24px !important',
        left: '0 !important',
    },
});

const Root = styled.div``;

const FormIndependentSelect = (props: FormIndependentSelectProps): React.ReactElement => {
    const { className, children, ...otherProps } = props;

    const selectPlatoonPopoverClasses = useSelectPlatoonPopoverStyles();

    const [open, setOpen] = React.useState<boolean>(false);

    const handleOpen = (): void => setOpen(true);
    const handleClose = (): void => setOpen(false);

    const { SelectProps = {} } = otherProps;

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Root className={className}>
                <TextField
                    {...otherProps}
                    select
                    SelectProps={{
                        ...SelectProps,
                        open: open,
                        onOpen: handleOpen,
                        onClose: handleClose,
                        MenuProps: {
                            ...SelectProps.MenuProps,
                            disablePortal: true,
                            disableScrollLock: true,
                            PopoverClasses: selectPlatoonPopoverClasses,
                        },
                    }}
                >
                    {children}
                </TextField>
            </Root>
        </ClickAwayListener>
    );
};

export { FormIndependentSelect };
