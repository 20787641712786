import { useTranslation } from 'react-i18next';

type Option = Record<string, any>;

const useTranslationOptions = <O extends Option>(options: O[], by = 'value') => {
    const { t } = useTranslation();

    const result = options.map(option => {
        if (by in option) {
            return {
                ...option,
                [by]: t(option[by]),
            };
        }

        return option;
    });

    return result;
};

export { useTranslationOptions };
