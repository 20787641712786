import React from 'react';

import { StringHelper } from '@helpers';
import { checkPermission } from './check-permission';

import type { UserEntity } from '@modules/user/entities';
import type { Classifiers, ClassifiersKeys } from './typings';

type ClassifiersPermissions<T> = {
    [P in T extends ClassifiersKeys ? T : keyof any]: Record<string, boolean>;
} & { can: boolean };

type UsePermissionsResult<T> = ClassifiersPermissions<T>;

const ACCESS_PREFIX = 'can';

const usePermissions = <T>(user: UserEntity, classifiers: Classifiers): UsePermissionsResult<T> => {
    const result: UsePermissionsResult<T> = React.useMemo(() => {
        const role = user.getCurrentRole();

        let classifiersPermissions: any = { can: true };

        for (let key in classifiers) {
            const inputClassifiers: string[] = classifiers[key];

            let summaryCan = true;

            const permissionForClassifiers = inputClassifiers.reduce((carry, classifier) => {
                const sanitizedClassifier = classifier
                    .split('-')
                    .map(StringHelper.capitalizeFirstLetter)
                    .join('');

                const transformedClassifierName = ACCESS_PREFIX + sanitizedClassifier;

                const canPermission = checkPermission(role, key, classifier);

                if (!canPermission) {
                    summaryCan = false;
                }

                carry[transformedClassifierName] = canPermission;

                return carry;
            }, {});

            classifiersPermissions[key] = {
                can: summaryCan,
                ...permissionForClassifiers,
            };

            if (!summaryCan) {
                classifiersPermissions.can = summaryCan;
            }
        }

        return classifiersPermissions;
    }, [user]);

    return result;
};

export { usePermissions };
