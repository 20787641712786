import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';

import { FileInfoEntity } from './file-info-entity';

import type { FileDoc } from '@modules/types/graphql';

export type Entity = Partial<FileDoc> | null;

@DecribeEntity('FileDocumentEntity')
class FileDocumentEntity extends BaseEntity {
    name: string;
    fileId: string;

    @Relation(() => FileInfoEntity)
    file: FileInfoEntity;

    constructor(entity: Entity) {
        super(entity);

        this.name = entity?.name ?? '';
        this.fileId = entity?.fileId ?? '';
    }
}

export { FileDocumentEntity };
