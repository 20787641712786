import React from 'react';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

import type { InputBaseComponentProps } from '@material-ui/core';

type HoursMinutesMaskProps = Omit<InputBaseComponentProps, 'onKeyDown'>;

const autoCorrectedHoursMinutesPipe = createAutoCorrectedDatePipe('HH:MM');

const HoursMinutesMask = (props: HoursMinutesMaskProps): React.ReactElement => {
    const { inputRef, value, ...other } = props;

    return (
        <MaskedInput
            showMask
            ref={ref => inputRef(ref ? ref.inputElement : null)}
            value={value}
            mask={[/\d/, /\d/, ':', /\d/, /\d/]}
            pipe={autoCorrectedHoursMinutesPipe}
            placeholderChar='-'
            {...other}
        />
    );
};

export { HoursMinutesMask };
