import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';
import { LapEntity } from './lap-entity';

import type { LapAccess, LapAccessTarget } from '@modules/types/graphql';

export type Entity = Partial<LapAccess> | null;

@DecribeEntity('LapAccessEntity')
class LapAccessEntity extends BaseEntity {
    id: string;
    blocked: boolean;
    target: LapAccessTarget | null;
    targetId: string;
    studentCount: number;

    @Relation(() => LapEntity)
    lap: LapEntity;

    constructor(lapAccess: Entity) {
        super(lapAccess);

        this.id = lapAccess?.id ?? '';
        this.blocked = lapAccess?.blocked ?? true;
        this.target = lapAccess?.target ?? null;
        this.targetId = lapAccess?.targetId ?? '';
        this.studentCount = lapAccess?.studentCount ?? 0;
    }
}

export { LapAccessEntity };
