import { TokenSession } from '@lib/token';
import { changePlatform } from '@lib/routing';

import type { ApolloClient } from '@apollo/client';

const logout = async (client: ApolloClient<any>): Promise<any> => {
    const refreshToken = TokenSession.getCurrentSession().getRefreshToken().getToken();

    if (!refreshToken) {
        return;
    }

    TokenSession.destroyCurrentSession();
    await client.clearStore();

    changePlatform();
};

export { logout };
