import { EntityList } from '@lib/entity';
import { BuildingRoomEntity } from './building-room-entity';

import type { Entity } from './building-room-entity';

class BuildingRoomEntityList extends EntityList<BuildingRoomEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(BuildingRoomEntity, items, count);
    }
}

export { BuildingRoomEntityList };
