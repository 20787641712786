import { rgba } from 'polished';
import { muiTheme } from '@avangard/ui/theme';
import { red, white, black } from '@avangard/ui/colors';

const animate = {
    duration: 500,
    onLoad: { duration: 1000 },
};

export const tickLabels = {
    padding: 16,
    fill: black[50],
    fontFamily: muiTheme.typography.fontFamily.toString(),
    fontSize: 14,
    fontWeight: 400,
};

export const chartTheme = {
    dependentAxis: {
        tickLabels,
        axis: { stroke: 'transparent' },
        grid: { stroke: rgba(red[100], 0.1) },
    },
    independentAxis: {
        tickLabels,
        axis: { stroke: 'transparent' },
        grid: { stroke: 'transparent' },
    },
    tooltip: {
        fill: white[100],
        fontFamily: muiTheme.typography.fontFamily.toString(),
        fontSize: 14,
        fontWeight: 400,
    },
};

export const chartProps = {
    chart: {
        theme: {}, // need for draw bar with empty value
    },
    bar: {
        animate,
        cornerRadius: { top: 1, bottom: 1 },
    },
    area: {
        animate,
        interpolation: 'monotoneX' as const,
    },
    pie: {
        animate,
    },
    tooltip: {
        cornerRadius: 4,
        pointerWidth: 10,
        pointerLength: 6,
        flyoutHeight: 32,
        flyoutPadding: 8,
        flyoutStyle: {
            stroke: black[70],
            fill: black[70],
        },
    },
};
