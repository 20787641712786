import React from 'react';
import styled from 'styled-components';
import { gray, white } from '@avangard/ui/colors';

type AvatarProps = {
    fullName: string;
    croppedThumbnail?: string | null;
    size?: number;
};

const Root = styled.div<{ size: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 0 0 auto;
    width: ${p => `${p.size}px`};
    height: ${p => `${p.size}px`};
    color: ${white[100]};
    font-size: ${p => `${p.size / 2}px`};
    text-align: center;
    text-transform: uppercase;
    background: ${gray[80]};
    border-radius: 50%;
    overflow: hidden;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;

const Avatar = React.memo((props: AvatarProps): React.ReactElement => {
    const { fullName, size = 40, croppedThumbnail } = props;

    const getInitials = (infoName: string) =>
        React.useMemo(() => {
            if (!infoName || infoName.length === 0) {
                return '-';
            }

            const words = infoName.split(' ');
            let initials = '';

            if (words[0]) {
                initials += words[0][0];
            }

            if (words[1]) {
                initials += words[1][0];
            }

            return initials;
        }, [fullName]);

    const initials = getInitials(fullName);

    if (croppedThumbnail) {
        return (
            <Root size={size}>
                <img src={croppedThumbnail} alt={initials} />
            </Root>
        );
    }

    return <Root size={size}>{initials}</Root>;
});

export { Avatar };
