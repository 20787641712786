import React from 'react';
import styled from 'styled-components';

import { Loader } from './loader';

type WithLoaderProps = {
    loading: boolean;
    children: React.ReactNode;
};

const Root = styled.div`
    width: 100%;
    height: initial;
`;

const WithLoader = (props: WithLoaderProps): React.ReactElement => {
    const { loading, children } = props;

    if (loading) {
        return (
            <Root>
                <Loader />
            </Root>
        );
    }

    return <Root>{children}</Root>;
};

export { WithLoader };
