export enum SportCategory {
    junior_3 = '3_junior',
    junior_2 = '2_junior',
    junior_1 = '1_junior',
    sport_3 = '3_sport',
    sport_2 = '2_sport',
    sport_1 = '1_sport',
    kms = 'kms',
    ms = 'ms',
    msmk = 'msmk',
}

export const sportCategoryTranslates = {
    [SportCategory.junior_3]: 'students:blocks.sport_category.junior_3',
    [SportCategory.junior_2]: 'students:blocks.sport_category.junior_2',
    [SportCategory.junior_1]: 'students:blocks.sport_category.junior_1',
    [SportCategory.sport_3]: 'students:blocks.sport_category.sport_3',
    [SportCategory.sport_2]: 'students:blocks.sport_category.sport_2',
    [SportCategory.sport_1]: 'students:blocks.sport_category.sport_1',
    [SportCategory.kms]: 'students:blocks.sport_category.kms',
    [SportCategory.ms]: 'students:blocks.sport_category.ms',
    [SportCategory.msmk]: 'students:blocks.sport_category.msmk',
};

export const sportCategoryOptions: AutocompleteOption[] = Object.entries(SportCategory).map(
    ([, value]) => ({
        key: value,
        value: sportCategoryTranslates[value],
    }),
);
