import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@avangard/ui/core';
import { breakpoints } from '@avangard/ui/utils';

import { AUTH_DOMAIN } from '@config/environment';
import { AccessErrorPlaceholderIcon } from '@modules/layout/helpers';

const Root = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`;

const Image = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex: 0 0 auto;
    width: 254px;
    margin-bottom: 32px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    ${breakpoints.down(560)} {
        width: 190px;
    }
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    justify-content: center;

    & > *:not(:last-child) {
        margin-bottom: 30px;
    }
`;

const Text = styled.p`
    font-size: 2.2rem;
`;

// TODO: Create handler placeholder of error types
const ErrorPlaceholder = (): React.ReactElement => {
    const { t } = useTranslation(['errors']);

    const handleClickChangePlatform = (): void => {
        window.location.href = AUTH_DOMAIN;
    };

    return (
        <Root>
            <Image>
                <img src={AccessErrorPlaceholderIcon} alt='' />
            </Image>

            <Content>
                <Text>{t('errors:layout.have_not_access')}</Text>
                <Button onClick={handleClickChangePlatform}>{t('errors:layout.to_index')}</Button>
            </Content>
        </Root>
    );
};

export { ErrorPlaceholder };
