import { QueryHookOptions, useQuery } from '@apollo/client';

import { HasAccessForPlatformQuery } from '@modules/auth/graphql';

import type { HasAccessForPlatformQueryType } from '@modules/types/graphql';

const useHasAccessForPlatform = (options?: QueryHookOptions<HasAccessForPlatformQueryType>) => {
    const queryResult = useQuery<HasAccessForPlatformQueryType>(HasAccessForPlatformQuery, options);

    const hasAccessForPlatform = queryResult?.data?.hasAccessForPlatform || false;

    const result = {
        hasAccessForPlatform,
        ...queryResult,
    };

    return result;
};

export { useHasAccessForPlatform };
